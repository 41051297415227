body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: 'Montserrat', sans-serif;
}

p {
  line-height: 1.5;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fontFamily {
  font-family: 'Montserrat', sans-serif !important;
}

.marginBottom4px {
  margin-bottom: 4px !important;
}

.marginBottom8px {
  margin-bottom: 8px !important;
}

.marginBottom16px {
  margin-bottom: 16px;
}

.marginBottom24px {
  margin-bottom: 24px;
}

.marginBottom48px {
  margin-bottom: 48px;
}

.marginRight12px {
  margin-right: 12px;
}

.marginLeft12px {
  margin-left: 12px;
}



.marginTop24px {
  margin-top: 24px;
}

.opacity50 {
  opacity: .5;
}

.width100Percent {
  width: 100%;
}
