/* todo: Rob I put this here temporarily */
.flex-justifyCenter {
  display: flex;
  justify-content: center;
}

.positionRelative {
  position: relative;
}

.flex-spaceApart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-alignItems-center {
  align-items: center;
}

/* .flex-alignItems-bottom {
  align-items: end;
} */

.newCampaignValue {
  border: 4px solid #000;
  padding: 12px 24px;
  margin: 0 12px;
  text-align: center;
  font-size: clamp(1.5rem, 0.375rem + 2vw, 1.5rem);
  font-weight: 700;
  min-width: 160px;
}

.newCampaignValue:hover {
  background-color: #eee;
}

@media screen and (max-width: 999px) {
  .newCampaignValue {
    width: 100%;
    margin-bottom: 24px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

.mainH1 {
  font-size: 48px;
  line-height: 1;
}

.centerText {
  text-align: center;
}

.nav {
  display: flex;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  padding: 16px 32px;
  font-weight: 700;
  border-bottom: 3px solid #000;
}

.links {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .links {
    display: none;
  }
  .nav-hamburgerButton {
    display: block;
  }
}

.nav-hamburgerButton {
  display: flex;
}

@media screen and (min-width: 901px) {
  .links {
    display: flex;
  }
  .nav-hamburgerButton {
    display: none;
  }
}

.menuButton {
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 700;
  padding: 0;
  font-family: "Montserrat", sans-serif !important;
}

.nav-slideOut {
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 100000;
  background-color: #fff;
  padding: 32px 16px;
  box-sizing: border-box;
}

.nav-slideOut .linkInNav {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 32px;
}

.menuButton_close {
  padding: 0;
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif !important;
}

.links-inSlideOut {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 24px;
}

.linkInNav {
  padding: 0 24px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

.linkInNav:hover {
  text-decoration: underline;
}

.link-helpMe {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  cursor: pointer;
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: "Montserrat", sans-serif !important;
}

.logo {
  font-size: 20px;
}

.googleLogin {
  border: 3px solid #000 !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #000 !important;
  margin: auto 0px;
}

.googleLogin:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.googleButtonThinger {
  justify-content: center;
  width: 100%;
  background-color: aliceblue;
}

.heroDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  padding: 0px 32px;
}

.textContainer {
  max-width: 800px;
  justify-content: center;
}

.mainButton {
  border-radius: 0px;
  color: #fff;
  background-color: #000;
  font-size: 16px;
  font-weight: 700;
  font-family: "Montserrat";
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  border: 3px solid #000;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 500px) {
  .mainButton {
    padding: 10px 12px;
    font-size: 14px;
  }
}

.mainButton__white {
  background-color: #fff !important;
  color: #000;
  border: none;
  border: 3px solid #fff;
}

.mainButton__white:hover {
  border: solid 3px #fff !important;
  background-color: #000 !important;
}

.mainButton:hover {
  background-color: #fff !important;
  border: 3px solid #000;
  color: #000 !important;
}

.mainButton--outlined {
  background-color: #fff;
  color: #000;
}

.mainButton--outlined:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.mainButton_smaller {
  padding: 8px 12px;
  font-size: 14px;
}

.newCampaignButton {
  border-radius: 0px;
  color: #000;
  background-color: #fff;
  font-size: 24px;
  font-weight: 700;
  font-family: "Montserrat";
  padding: 48px 72px;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  border: 3px solid #000;
}

.newCampaignButton:hover {
  background-color: #000;
  color: #fff;
}

.loginButton {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif !important;
  padding: 12px 16px !important;
  background-color: #4285f4 !important;
  border: 3px solid #4285f4;
}

.loginButton:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.loginButtonDiv {
  background-color: #fff;
  padding: 8px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginButton img {
  max-width: 24px;
}

.mainButton:hover {
  background-color: #000;
  color: #fff;
  cursor: pointer;
}

.currentVsPastCampaignText {
  color: #000;
  opacity: 1;
  font-weight: 700;
  font-size: 24px;
  margin: 0;
  cursor: pointer;
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: "Montserrat", sans-serif !important;
}

.pageHeaderContainer {
  max-width: 1100px;
  margin: 48px auto 24px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.dashboard_buttonDiv {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 900px) {
  .pageHeaderContainer {
    display: block;
  }

  .pageHeaderContainer .currentVsPastCampaignText {
    margin-bottom: 24px;
  }

  .currentVsPastCampaignText {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  .currentVsPastCampaignText {
    font-size: 16px;
  }
}

.currentVsPastCampaignText:hover {
  opacity: 0.5;
}

.campaignScheduledDiv {
  background-color: #000;
  width: 100%;
  padding: 16px;
  color: #fff;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.campaignScheduledDiv p {
  line-height: 16px;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.dashboardBoxSection--2 {
  width: 45%;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.dashboardBox--dataContainer {
  display: flex;
  flex-direction: row;
  align-content: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 12px;
}

.dashboardBox--data {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 30%;
}

.dashboardBox--peopleAdded {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 150px;
  margin-left: 150px;
}

.fontAwesome--refreshStats {
  font-weight: bold;
  color: #fff;
  margin-right: 4px;
  height: 16px;
  width: 16px;
}

.fontAwesome--refreshStats:hover {
  color: #000;
}

.errorMessageOnDashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}

.dashboardBoxSection--3 {
  width: 20%;
  display: flex;
  flex-direction: column;
}

.dashboard-noCampaigns {
  width: 100%;
  padding: 80px 32px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.dashboard-noCampaigns h4 {
  font-size: 24px;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.dashboard-noCampaigns p {
  max-width: 720px;
  padding: 0;
  margin: 0;
}

.dashboardBoxSection--1 {
  max-width: 35%;
}

@media screen and (max-width: 740px) {
  .dashboardBoxSection--1 {
    width: 100%;
    margin-bottom: 48px;
  }
  .dashboardBoxSection--2 {
    width: 100%;
    margin-bottom: 48px;
  }
  .dashboardBoxSection--3 {
    width: 100%;
  }

  .dashboardBox {
    flex-wrap: wrap;
  }
}

.labelInBox {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  opacity: 0.5;
  margin: 0;
}

.boldTextInBox {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin: 0;
  line-height: 1;
}

.emailContainer {
  display: flex;
  padding: 24px;
  border: 4px solid #000;
  justify-content: space-between;
  align-items: center;
}

.paddingOnSideOfPage {
  padding: 0 32px;
}

.textColor--white {
  color: #fff;
}

.dropdown {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.dropdown--item {
  padding-bottom: 16px;
  cursor: pointer;
}

.inputBox {
  border: 3px solid #000;
  height: 48px;
  font-size: 16px;
  padding: 0 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "Montserrat";
}

.inputBox_width100 {
  width: 100%;
}
.textAreaDiv {
  height: 100%;
}

.textArea {
  border: 3px solid #000;
  font-size: 16px;
  padding: 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
  min-height: 320px;
  max-height: 480px;
  overflow-x: hidden;
  overflow-y: auto;
}

.box--border--black {
  display: block;
  justify-content: space-between;
  align-items: center;
  border: 4px solid #000;
  margin-bottom: 32px;
  padding: 32px;
  min-height: 160px;
}

.container__newCampaign {
  margin-top: 64px !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.container__newCampaign__buttonDiv {
  padding: 24px 0;
  display: flex;
  justify-content: center;
}

.container__newCampaign__buttonDiv button {
  margin: 0 12px;
}

.container--maxWidth1100px {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 24px 160px 24px;
}

.container--maxWidth900px {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 24px;
}

.container--maxWidth1100px--height80vh--centerVertAndHorz {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container--maxWidth1100px--paddingTop64px {
  padding-top: 64px;
}

.container--paddingBottom64px {
  padding-bottom: 64px;
}

.modal__buttonContainer--rightLeft {
  display: flex;
  justify-content: space-between;
}

.buttonDiv--rightSide {
  display: flex;
  justify-content: right;
}

.width100 {
  width: 100% !important;
}

.position--relative {
  position: relative;
}

.textLabel {
  color: #a6a6a6;
  font-weight: 700;
  margin: 0 0 4px 0;
  font-size: 16px;
}

.dataText {
  color: #000;
  font-weight: 700;
  margin: 0;
  font-size: 24px;
}

.dataText--white {
  color: #fff;
}

.fontSize--20px {
  font-size: 20px;
}

.hugeInput {
  border: #000 solid 4px;
  font-size: clamp(1.125rem, 0.6563rem + 1.5vw, 1.5rem);
  width: 100%;
  box-sizing: border-box;
  padding: 16px 12px;
  font-family: "Montserrat", sans-serif !important;
}

.logoutButton {
  all: unset;
}

/* loading spinner */
.loading {
  display: flex;
  justify-content: center;
}

.loading::after {
  content: "";
  width: 20px;
  height: 20px;
  /*border: 10px solid #dddddd;*/
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  /*animation: loading 1s linear infinite;*/
  animation: loading 1s ease infinite;
}

@keyframes loading {
  to {
    transform: rotate(1turn);
  }
}

.topAlertBar {
  width: 100%;
  height: 64px;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topAlertBar a {
  position: absolute;
  right: 24px;
  font-weight: 700;
  cursor: pointer;
}

.topAlertBar a:hover {
  color: #fff;
  opacity: 0.6;
}

/* This is stuff in the modal */
.modal--buttonDiv {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  align-items: center;
}

.closeButton {
  font-weight: 700;
  cursor: pointer;
}

.closeButtonTopRight {
  font-weight: 700;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 16px;
}

.selectInModal {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  border: 2px solid #000;
  padding: 8px 24px 8px 8px;
  border-radius: 0px !important;
  margin-top: 8px;
}

.closeButton {
  cursor: pointer;
  display: inline-block;
}

.closeButton:hover {
  font-weight: 600;
}

.googleDisclaimer {
  position: absolute;
  bottom: 0;
  max-width: 640px;
  padding: 0 32px;
}

/* Confirmation Page */
.confimationPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.enormousText {
  font-size: clamp(5rem, 1.6566rem + 14.4578vw, 12.5rem);
  font-weight: 800;
  line-height: 1;
}

.confirmationHeader {
  font-size: clamp(1.25rem, 0.9157rem + 1.4458vw, 2rem);
  line-height: 0;
  font-weight: 800;
  margin: 96px 0;
}

.confirmation-nextStepSection {
  width: 100%;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
}

.confirmation-nextStepDiv {
  display: flex;
  margin: 0 -12px;
  max-width: 960px;
  flex-wrap: wrap;
  padding: 80px 48px;
}

.confirmation-nextStep-item {
  max-width: 33.33%;
  padding: 12px;
  box-sizing: border-box;
}

.confirmation-nextStep-item p {
  margin: 0px;
  padding: 0px;
}

.confirmation-nextStep-item h3 {
  margin: 0 0 8px 0;
  padding: 0px;
  font-size: 24px;
}

.width100 h2 {
  font-size: 32px;
}

.confirmation-nextStep-container {
  display: flex;
  margin: 0 -12px;
}

@media screen and (max-width: 640px) {
  .confirmation-nextStep-container {
    display: block;
  }

  .confirmation-nextStep-item {
    max-width: 100%;
    padding: 0px;
    margin-bottom: 40px;
  }
}

/* Create Campaign CSS */
.header-newCampaignSteps {
  margin: 0;
  padding: 0;
}

.paragraph-newCampaignSteps {
  padding: 0;
  margin: 8px 0 24px 0;
  max-width: 720px;
}

/* Email Campaign Page CSS */
.emailSetupPage-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-top: #000 3px solid;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

/********** PADDING AND MARGIN DEFAULT STYLES **********/

/* Padding All Around */
.padding24px {
  padding: 24px;
}

/* Padding Top */
.paddingTop4px {
  padding-top: 4px;
}

.paddingTop8px {
  padding-top: 8px;
}

.paddingTop12px {
  padding-top: 12px;
}

.paddingTop16px {
  padding-top: 16px;
}

.paddingTop24px {
  padding-top: 24px;
}

.paddingTop32px {
  padding-top: 32px;
}

/* Padding Right */
.paddingRight4px {
  padding-bottom: 4px;
}

.paddingRight8px {
  padding-bottom: 8px;
}

.paddingRight12px {
  padding-bottom: 12px;
}

.paddingRight16px {
  padding-bottom: 16px;
}

.paddingRight24px {
  padding-bottom: 24px;
}

/* Padding Bottom */
.paddingBottom4px {
  padding-bottom: 4px;
}

.paddingBottom8px {
  padding-bottom: 8px;
}

.paddingBottom12px {
  padding-bottom: 12px;
}

.paddingBottom16px {
  padding-bottom: 16px;
}

.paddingBottom24px {
  padding-bottom: 24px;
}

/* Padding Left */
.paddingLeft4px {
  padding-bottom: 4px;
}

.paddingLeft8px {
  padding-bottom: 8px;
}

.paddingLeft12px {
  padding-bottom: 12px;
}

.paddingLeft16px {
  padding-bottom: 16px;
}

.paddingLeft24px {
  padding-bottom: 24px;
}

/* Margin Top */
.marginTop4px {
  margin-top: 4px;
}

.marginTop8px {
  margin-top: 8px;
}

.marginTop12px {
  margin-top: 12px;
}

.marginTop16px {
  margin-top: 16px;
}

.marginTop24px {
  margin-top: 24px;
}

.marginTop48px {
  margin-top: 48px;
}

/* Margin Right */
.marginRight4px {
  margin-right: 4px;
}

.marginRight8px {
  margin-right: 8px;
}

.marginRight12px {
  margin-right: 12px;
}

.marginRight16px {
  margin-right: 16px;
}

.marginRight24px {
  margin-right: 24px;
}

/* Margin Bottom */
.marginBottom4px {
  margin-bottom: 4px;
}

.marginBottom8px {
  margin-bottom: 8px;
}

.marginBottom12px {
  margin-bottom: 12px;
}

.marginBottom16px {
  margin-bottom: 16px;
}

.marginBottom24px {
  margin-bottom: 24px;
}

.marginBottom48px {
  margin-bottom: 48px;
}

/* Margin Left */
.marginLeft4px {
  margin-left: 4px;
}

.marginLeft8px {
  margin-left: 8px;
}

.marginLeft12px {
  margin-left: 12px;
}

.marginLeft16px {
  margin-left: 16px;
}

.marginLeft24px {
  margin-left: 24px;
}

.opacity50 {
  opacity: 0.5;
}

/* Other Margin Things */
.noMargin {
  margin: 0;
}

/* Date Picker Page */
.addPeople_container {
  display: flex;
}

@media (max-width: 820px) {
  .addPeople_container {
    display: block;
  }
}

.addPeople_container .emailContainer {
  width: 100%;
  margin: 0px 12px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 640px) {
  .addPeople_container .emailContainer {
    width: auto;
    min-height: 160px;
    margin-bottom: 24px;
  }

  .emailContainer {
    display: block;
  }

  .emailContainer .emailContainer_text {
    margin-bottom: 24px;
  }
}

.react-datepicker-wrapper {
  border: #000 3px solid !important;
  border-radius: 0px !important;
  height: 48px !important;
  box-sizing: border-box !important;
}

.react-datepicker__input-container {
  height: 100% !important;
}

.react-datepicker__input-container input {
  height: 100% !important;
  box-sizing: border-box !important;
  border: none !important;
  padding: 0 12px !important;
  font-size: 16px !important;
}

.addPeople_dropdownElement {
  justify-content: left;
  text-align: left;
  width: 50%;
  margin: 0 12px;
  box-sizing: border-box;
}

.addPeople_sideBySide {
  display: flex;
}

@media (max-width: 600px) {
  .addPeople_dropdownElement {
    width: 100%;
    margin: 0 0 16px 0;
  }

  .addPeople_sideBySide {
    display: block;
    width: 100%;
  }
}

.react-datepicker__header {
  background-color: #000 !important;
}

.react-datepicker__current-month {
  color: #fff !important;
}

.react-datepicker__day-name {
  color: #fff !important;
}

.react-datepicker {
  font-family: "Montserrat" !important;
  border-radius: 0px !important;
  border: 3px solid #000 !important;
}

.react-datepicker__header {
  border-radius: 0px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0px !important;
  background-color: #000 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #eee !important;
  border-radius: 0px !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  visibility: hidden !important;
}

/* Pick a plan page */
.planChoices {
  display: flex;
  height: auto;
  margin: 0 -16px;
}

.halfSide {
  width: 100%;
  padding: 0 16px;
  margin-bottom: 32px;
}

.box-monthlyPlan {
  border: 3px solid #000;
  box-sizing: border-box;
  position: relative;
  height: 100%;
}

.box-annualPlan {
  background-color: #000;
  border: 3px solid #000;
  box-sizing: border-box;
  color: #fff;
  position: relative;
  height: 100%;
}

.font-planLabel {
  font-size: 20px;
  opacity: 0.5;
  margin: 0;
}

.font-price {
  font-size: 56px;
  font-weight: 700;
  margin: 0;
}

.font-per {
  font-size: 28px;
  font-weight: 700;
  margin: 0;
}

.font-whatYouGet {
  opacity: 0.5;
  margin: 24px 0 0 0;
}

.div-buttonBottom {
  position: absolute;
  bottom: 0;
  padding: 16px 24px;
  margin-top: 32px;
}

.font-pickAPlan {
  text-align: center;
  margin: 48px 0 24px 0;
  font-size: 32px;
}

/* Mobile changes for the paymentOptions page */
@media (max-width: 820px) {
  .planChoices {
    flex-wrap: wrap;
  }

  .font-pickAPlan {
    font-size: 24px;
  }
}

/* Pick Campaign */
.pickCampaign-email {
  max-width: 360px;
  margin: 0 auto;
  padding: 0 24px;
}

.pickCampaign-grid {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 24px 0;
}

.showEmailsLink {
  font-size: 16px;
  font-weight: 700;
  opacity: 0.5;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 24px;
  left: 0;
  width: 100%;
  text-align: center;
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: "Montserrat", sans-serif !important;
}

.showEmailsLink:hover {
  opacity: 1;
}

.display-flex {
  display: flex;
}

.pickCampaign-arrowDiv {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pickCampaign-arrowDiv:hover {
  background-color: #eee;
}

.flipArrow {
  transform: rotate(180deg);
}

.grid-side {
  display: flex;
  align-items: center;
}

/* Robs Test Code */
.personalize_topSection {
  padding: 24px 48px;
  border-bottom: 3px solid #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.personalize_links {
  margin: 0 8px;
}

@media (max-width: 600px) {
  .personalize_topSection {
    padding: 24px;
    flex-wrap: wrap;
  }

  .personalize_topSection_name {
    width: 100%;
    margin-bottom: 16px;
  }

  .personalize_buttons {
    display: flex;
    margin: 0 -4px;
  }

  .personalize_buttons_linkedinMobile {
    margin-right: 16px;
  }
}

.personalize_section2 {
  display: flex;
  flex: 1;
}

.personalize_leftSide {
  width: 70%;
  height: 83vh;
  border-right: 3px solid #000;
}

.personalize_rightSide {
  width: 30%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .personalize_leftSide {
    display: none;
  }

  .personalize_rightSide {
    width: 100%;
  }
}

.personalize_leftSide iframe {
  border: none;
  width: 100%;
  height: 100%;
}

.bottomButtonDiv {
  padding: 12px 24px;
  border-top: 3px solid #000;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  position: sticky;
}

.bottomButtonDiv button {
  font-weight: 700;
}

.textArea_noBorder {
  border: none !important;
  outline: none;
  max-height: none;
  resize: none;
  padding: 0 0 200px 0;
  font-size: 18px;
  height: 100%;
}

.robsTest_emailTabs {
  display: flex;
}

.emailTab {
  width: 100%;
  background-color: #aaa;
  color: #fff;
  font-size: 14px;
  padding: 12px 0;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

.emailTab:hover {
  background-color: #767676;
}

.emailTab_active {
  background-color: #000;
}

.subjectLineDiv {
  width: 100%;
  border-bottom: #000 3px solid;
  padding: 12px 24px;
  overflow: hidden;
  box-sizing: border-box;
}

.fieldLabel {
  font-size: 14px;
  font-weight: 700;
  opacity: 0.5;
  display: block;
}

.subjectLine {
  border: none !important;
  outline: none;
  max-height: none;
  max-width: 100%;
  resize: none;
  font-size: 18px;
  width: 100%;
}

.messageDiv {
  padding: 12px 24px;
  flex-grow: 1;
}

.labelDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.addThing {
  font-size: 14px;
  font-weight: 700;
  opacity: 0.7;
  background-color: #eee;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0 !important;
}

.dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px 0 24px;
  margin-bottom: 12px;
}

.dropdown-options {
  list-style: none;
  padding: 0;
}

.dropdown-options li {
  padding: 12px 24px;
}

.dropdown-header {
  cursor: pointer;
}

.custom-dropdown {
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
}

.tab-pane {
  display: none;
}

.closeEmailPreview {
  background: none !important;
  border: none;
  padding: 0 !important;
}

.notAFit {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: "Montserrat", sans-serif !important;
  cursor: pointer;
}

.notAFit:hover {
  opacity: 0.6;
}

/* TABLE STYLES */
.tableContainer {
  max-width: 1200px;
  padding: 48px 0;
  margin: 0 auto;
}

.mainTable {
  border: #000 3px solid;
}

.mainTable th {
  min-width: 100px;
}

table {
  border-spacing: 0;
}

thead {
  background-color: #d9d9d9;
}

th {
  border: none;
  margin: 0;
  padding: 8px;
  text-align: left;
  font-size: 14px;
  width: 20%;
}

td {
  padding: 8px;
  border-bottom: #eee 2px solid;
  width: 15%;
  white-space: nowrap;
  overflow: hidden;
}

.borderBottom {
  border-bottom: #eee 2px solid;
}

.column-linkedIn {
  max-width: 160px;
  text-overflow: ellipsis;
}

.column-website {
  max-width: 160px;
  text-overflow: ellipsis;
}

.column-email {
  max-width: 160px;
  text-overflow: ellipsis;
}

.pagination_buttons {
  text-align: center;
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pagination_buttons span {
  margin: 0 16px;
  font-size: 16px;
}

.active {
  opacity: 0.3;
}

.iconPlaceholder {
  width: 20px;
  height: 20px;
  background-color: #eee;
}

.csvLink {
  font-size: 18px;
  font-weight: 700;
  margin-right: 16px;
  cursor: pointer;
}

.csvLink:hover {
  opacity: 0.5;
}

.form_sideBySide {
  display: flex;
}

/* Personalize Page */
.blackBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.getStoked {
  font-size: 80px;
  line-height: 80px;
  margin: 0;
}

@media screen and (max-width: 740px) {
  .getStoked {
    font-size: 64px;
    line-height: 64px;
  }
}

/* SEQUENCE PAGE CSS */
.sequenceSelectText {
  margin: 0;
}

.sequenceList_header {
  margin: 0;
}

.sequenceList_subHeader {
  margin: 0;
}

.sequenceList_item {
  cursor: pointer;
}

.sequenceList_item:hover {
  background-color: #f5f5f5;
}

.sequence_dataDiv {
  display: grid;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 012px;
}

.sequence_box_topDiv {
  display: flex;
  justify-content: space-between;
}

.sequence_pageLabelAndButtonDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap;
}

@media screen and (max-width: 740px) {
  .sequence_box_topDiv {
    display: block;
  }

  .dashboardBoxSection--1 {
    max-width: 100%;
  }

  .sequence_dataDiv {
    margin-bottom: 24px;
  }

  .sequencesChange {
    padding-bottom: 64px !important;
  }

  .box--border--black {
    padding: 24px;
  }
}

@media screen and (max-width: 416px) {
  .sequencePageLabel {
    margin-bottom: 24px;
  }
}

/* PEOPLE PAGE CSS */
.peopleTable_icons {
  display: flex;
}

.peopleTable_iconDiv:hover {
  opacity: 0.5;
}

.peopleTabs {
  display: flex;
}

.peopleTabs h2 {
  cursor: pointer;
  margin-right: 24px;
}

.peoplePage_pageLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
}

.peoplePage_tableDiv {
  padding: 0 32px;
  overflow: scroll;
}

@media screen and (max-width: 770px) {
  .peoplePage_pageLabel {
    display: block;
  }

  .peoplePage_buttonsDiv {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 500px) {
  .peopleTabs h2 {
    font-size: 18px;
  }

  .peoplePage_pageLabel {
    padding: 0 24px;
  }

  .peoplePage_tableDiv {
    padding: 0 24px;
  }
}

.emailCustomization_div {
  border: 2px solid #000;
  width: 100%;
  margin-bottom: 8px;
}

.emailCustomization_textarea {
  width: 100%;
  border: none;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
  padding: 8px;
  resize: none;
  min-height: 100px;
  font-size: 16px;
  line-height: 24px;
}

.emailCustomization_textInput {
  border: none;
  padding: 8px;
  font-size: 16px;
  line-height: 24px;
}

.emailCustomization_div input:focus {
  border: none;
  outline: none;
}

.emailCustomization_div textarea:focus {
  border: #000;
  outline: none;
}

.emailCustomization_textarea:focus {
  border: #000 !important;
}

.emailCustomization_header {
  background-color: #ddd;
  padding: 8px;
  border-bottom: 1px solid #000;
}

.emailCustomization_header p {
  font-size: 12px;
  font-weight: 700;
  opacity: 0.6;
}

/* CSV EXPORT TABLE */
.csvTable_container {
  border: 3px solid #000;
  padding: 48px;
}

.csvTable_innerDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.csvTable_field {
  border: 3px solid #000;
  margin: 0;
  padding: 6px 8px;
  width: 300px;
  font-family: "Montserrat";
  font-size: 16px;
}

.csvTable_select {
  border: 3px solid #000;
  margin: 0;
  padding: 8px;
  width: 300px;
  font-family: "Montserrat";
  font-size: 16px;
}

/* DAY INPUT */
.dayInput_div {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  padding: 0 20px;
}

.dayInput_input {
  border: none;
  outline: none;
  appearance: none;
  margin: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 40px;
  color: #fff;
  font-weight: bold;
}
